export const useAuth = () => {

    const localstoragekey = "auth_token"

    return {

        login: ( auth_token ) => localStorage.setItem(localstoragekey, auth_token),

        logout: () => localStorage.removeItem(localstoragekey),

        getAuthToken: () => localStorage.getItem(localstoragekey) ,

        isAuthenticated: () => localStorage.getItem(localstoragekey)?.length > 0 ,
        
    }
}